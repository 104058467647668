
import React  , {  useState , useEffect , useCallback  } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ButtonBase } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useTitle } from '../../../Hooks';
import { ActionsButtonsEnum , ActionsEnum  , ViewTypesEnum ,DateFilterTypesEnum   } from '../../../Enums' ; 
import { ActiveItemActions } from '../../../store/ActiveItem/ActiveItemActions';
import { Spinner  , ActionsButtonsComponent , ViewTypes    } from '../../../Components' ; 
import { PaginationComponent } from '../../../Components/PaginationComponent/PaginationComponent';
import { generateUniqueKey , GlobalHistory  , bottomBoxComponentUpdate  , havePermissionToViewDetails  , getSideMenuStatus  ,   sideMenuIsOpenUpdate,
  sideMenuComponentUpdate, CheckIsCommunicateAllowed , CheckIsWithinWorkingHours , showError ,
  WhatsAppMessage   } from '../../../Helper' ; 
import { formByIdGet    , MaqsamTokenServices  } from '../../../Services' ; 
import { DevelopersCreatedOnFilter  , DevelopersTable , DevelopersCards , CardDetailsComponent  } from './DevelopersViewComponent' ; 
import {  DeveloperVerticalTabsData    } from './DevelopersUtilities' ; 



 const parentTranslationPath = 'Developers';
 const translationPath = '';
  
  export const DevelopersView = () => { 

    const dispatch = useDispatch();
    const pathName = window.location.pathname.split('/home/')[1];
    const [isDatePickerChanged, setIsDatePickerChanged] = useState(false);


    const [detailedCardAction, setDetailedCardAction] = useState(() => ({
      actionEnum: '',
      item: '',
    }));
    
    const [activeActionType, setActiveActionType] = useState(
      (localStorage.getItem('ViewType') &&
        JSON.parse(localStorage.getItem('ViewType')).developers) ||
        ViewTypesEnum.cards.key
    );

    const [isExpanded, setIsExpanded] = useState(
      activeActionType === ViewTypesEnum.cardsExpanded.key
    );
    const [activeCard, setActiveCard] = useState(null);
    const [isFirst, setFirst] = useState(false);
    const [checkedCards, setCheckedCards] = useState([]);
    const [checkedCardsIds, setCheckedCardsIds] = useState([]);
    const [displayOpenFileButton, setDisplayOpenFileButton] = useState(false);
    const [isOpenContactsActionDialog, setIsOpenContactsActionDialog] =
    useState(false);
    const [activeSelectedAction, setActiveSelectedAction] = useState('');
    const [isOpenMaqsamIframe, setIsOpenMaqsamIframe] = useState(false);
    const [advanceSearchBtn, setAdvanceSearchBtn] = useState(true);
    const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);


    const dateRangeDefault = {
      startDate: null,
      endDate: null,
      key: 'selection',
      selectedDateType: 1,
    };
    const [dateFilter, setDateFilter] = useState(
      orderFilter?.ContactsFilterDate?.startDate &&
        orderFilter?.ContactsFilterDate?.endDate !== null
        ? {
            startDate: new Date(
              orderFilter?.ContactsFilterDate?.startDate || null
            ),
            endDate: new Date(orderFilter?.ContactsFilterDate?.endDate || null),
            key: new Date(orderFilter?.ContactsFilterDate?.key || null),
            selectedDateType:
              orderFilter?.ContactsFilterDate?.selectedDateType || 1,
          }
        : dateRangeDefault
    );
    const [filterSearchDto, setFilterSearchDto] = useState(null);

    const [isConsentActionDialogOpen, setIsConsentActionDialogOpen] =
    useState(false);
  const [isWorkingHoursDialogOpen, setIsWorkingHoursDialogOpen] =
     useState(false);

    const [activeFormType, setActiveFormType] = useState(1);
    const loginResponse = useSelector((state) => state.login.loginResponse);
    const [sortBy, setSortBy] = useState(null);
    const [activeItem , setActiveItem ] = useState(null) ; 
    const [formBuilderForm ,setFormBuilderForm] = useState() ; 
    const [allDevelopers , setAllDevelopers ] = useState({result : [] , totalCount : 0 }) ; 


    const [isLoading , setIsLoading]  =  useState(false) ; 
    const [selectedOrderBy, setSelectedOrderBy] = useState({
      filterBy: orderFilter?.zeroMatchingSale?.filterBy,
      orderBy: orderFilter?.zeroMatchingSale?.orderBy,
    });

    const [orderBy, setOrderBy] = useState(
      selectedOrderBy.filterBy
        ? selectedOrderBy
        : { filterBy: 'CreatedOn', orderBy: 2 }
    );

    const [filter, setFilter] = useState({
      pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
      pageIndex: 0,
      filterBy: orderBy.filterBy || null,
      orderBy: orderBy.orderBy || null,
      createdBy: null,
    });

    const{ t } = useTranslation(parentTranslationPath);
    const [actionButtonsKey, setActionButtonsKey] = useState(generateUniqueKey());
    useTitle(t(`${translationPath}developers`));

    const onActionsButtonClicked = useCallback(
      (activeAction) => {
         if (activeAction === ActionsButtonsEnum[1].id) {
          GlobalHistory.push(
            `/home/developers/add`
          );
      }
      },
      []
    );

    const MaqsamTokenServicesAPI = useCallback(async (mobile) => {
      const result = await MaqsamTokenServices();
      if (result) {
        setIsOpenMaqsamIframe(true);
        var iframe = document.getElementById('maqsamiframe');
        if (iframe !== null) {
          let token = result;
          iframe.setAttribute(
            'src',
            `https://portal.maqsam.com/autologin?auth_token=${token};continue_path=/phone/dialer#autodial=${mobile}`
          );
        }
      }
    }, []);

    const contactActionsHandler = (actionEnum, item) => {
      setIsOpenContactsActionDialog(true);
      if (actionEnum === 'whatsappSolid') {
        const el = document.createElement('a');
        if (item?.mobile?.phone) {
          el.href = WhatsAppMessage(
            item?.whatsapp_mobile?.phone || item.mobile.phone
          );
          el.target = 'blank';
          el.click();
        } else showError(t(`${translationPath}Failure-Open-WhatsApp`));
      }
      if (actionEnum === 'phoneSolid') {
        MaqsamTokenServicesAPI(
          (item && item.mobile && item.mobile.phone) ||
            (item && item.contacts_person && item.contacts_person[0].mobile) ||
            null
        );
      }
    };



    const onTypeChanged = useCallback(
      (activeType) => {
        let ViewTypeData = JSON.parse(localStorage.getItem('ViewType'));
        if (ViewTypeData) {
          ViewTypeData.developers = activeType;
          localStorage.setItem('ViewType', JSON.stringify(ViewTypeData));
        } else
          localStorage.setItem(
            'ViewType',
            JSON.stringify({ developers: activeType })
          );
        
          setActiveActionType(activeType);

      },
      [setActiveActionType]
    );

    const detailedCardSideActionClicked = useCallback(
      (actionEnum, activeData) => async (event) => {
        dispatch(ActiveItemActions.activeItemRequest(activeData));
        event.stopPropagation();
        if (actionEnum === ActionsEnum.folder.key) {
          if (pathName === 'contact-sales') {
            GlobalHistory.push(
              `/home/contact-sales/contact-profile-edit?formType=${activeData.userTypeId}&id=${activeData.id}`
            );
          } else if (pathName === 'contact-lease') {
            GlobalHistory.push(
              `/home/contact-lease/contact-profile-edit?formType=${activeData.userTypeId}&id=${activeData.id}`
            );
          } else if (pathName === 'contact-property-management') {
            GlobalHistory.push(
              `/home/contact-property-management/contact-profile-edit?formType=${activeData.userTypeId}&id=${activeData.id}`
            );
          } else if (pathName === 'Contacts-CRM') {
            if (localStorage.getItem('parentContact'))
              localStorage.removeItem('parentContact');
            localStorage.setItem('parentContact', JSON.stringify(activeData));
            GlobalHistory.push(
              `/home/Contacts-CRM/contact-profile-edit?formType=${activeData.userTypeId}&id=${activeData.id}`
            );
          } else {
            GlobalHistory.push(
              `/home/contacts/contact-profile-edit?formType=${activeData.userTypeId}&id=${activeData.id}`
            );
          }
        }
      },
      [dispatch, pathName]
    );
    
  const closeSideMenu = () => {
    sideMenuComponentUpdate(<></>);
    sideMenuIsOpenUpdate(false);
  };

  const detailedCardActionClicked = useCallback(
    (actionEnum, item) => async (event) => {
      event.stopPropagation();
      setDetailedCardAction({
        actionEnum,
        item,
      });
      const isActionAllowed = await checkIsActionAllowed(item?.id);

      if (isActionAllowed) contactActionsHandler(actionEnum, item);
      else unAllowedActionHandler(item?.id);
    },
    []
  );
  const checkIsActionAllowed = async (contactId) => {
    const isWithinWorkingHours = await CheckIsWithinWorkingHours({ contactId });
    const isCommunicateAllowed = await CheckIsCommunicateAllowed({ contactId });
    return (isWithinWorkingHours && isCommunicateAllowed) || false;
  };

  const unAllowedActionHandler = async (contactId) => {
    const isWithinWorkingHours = await CheckIsWithinWorkingHours({ contactId });
    const isCommunicateAllowed = await CheckIsCommunicateAllowed({ contactId });

    if (!isWithinWorkingHours) {
      setIsWorkingHoursDialogOpen(true);
      return;
    }
    if (!isCommunicateAllowed) {
      setIsConsentActionDialogOpen(true);
      return;
    }
  };
  const getDevelopersData = useCallback(
    async (f) => {
      
        setIsLoading(true);
        const localFilterDto = f || filterSearchDto || {};
        // if (activeFormType) {
        //   localFilterDto.contacttypeid = [
        //     {
        //       searchType: TableFilterOperatorsEnum.equal.key,
        //       value: activeFormType,
        //     },
        //   ];
        // }
        // if (orderFilter.opportunityContact) {
        //   localFilterDto.opportunityContact = [
        //     {
        //       searchType: TableFilterOperatorsEnum.equal.key,
        //       value: orderFilter.opportunityContact,
        //     },
        //   ];
        // }
        // if (contactTableFilter) {
        //   Object.values(contactTableFilter)
        //     .filter((item) => item.searchableKey || item.displayPath)
        //     .map((item) => {
        //       if (localFilterDto[item.searchableKey || item.displayPath]) {
        //         localFilterDto[item.searchableKey || item.displayPath].push({
        //           searchType: item && item.operator,
        //           value: item && item.value,
        //         });
        //       } else if (item.value) {
        //         localFilterDto[item.searchableKey || item.displayPath] = [
        //           {
        //             searchType: item.operator,
        //             value: item.value,
        //           },
        //         ];
        //       } else if (
        //         !item.value &&
        //         (item.operator === TableFilterOperatorsEnum.isNotBlank.key ||
        //           item.operator === TableFilterOperatorsEnum.isBlank.key)
        //       ) {
        //         localFilterDto[item.searchableKey || item.displayPath] = [
        //           {
        //             searchType: item.operator,
        //             value: null,
        //           },
        //         ];
        //       }
        //       return undefined;
        //     });
        // }
        // if (Object.values(localFilterDto).length)
        //   setIsAdvanceSearchActive(true);
        // else setIsAdvanceSearchActive(false);

        let body = {
          criteria: localFilterDto,
          ...orderBy,
        };
        setIsDatePickerChanged(false);

        if (dateFilter && dateFilter.startDate && dateFilter.endDate) {
          const fromDate = moment(dateFilter.startDate)
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss');
          const toDate = moment(dateFilter.endDate).format(
            'YYYY-MM-DDTHH:mm:ss'
          );

          if (
            dateFilter.selectedDateType ===
            DateFilterTypesEnum.CreatedOnDate.key
          ) {
            body.fromDate = fromDate;
            body.toDate = toDate;
          } else if (
            dateFilter.selectedDateType ===
            DateFilterTypesEnum.UpdatedOnDate.key
          ) {
            body.updatedFromDate = fromDate;
            body.updatedToDate = toDate;
          }
        }

        // const res = await GetAdvanceSearchContacts(filter, body);
        // if (!(res && res.status && res.status !== 200)) {
        //   setDetailsContactsList({
        //     result: ((res && res.result) || []).map(
        //       (item) =>
        //         item &&  item.contactJson &&
        //         ContactsMapper(item, JSON.parse(item.contactJson).contact)
        //     ),
        //     totalCount: (res && res.totalCount) || 0,
        //   });
        // } else {
        //   setDetailsContactsList({
        //     result: [],
        //     totalCount: 0,
        //   });
        // }
        setIsLoading(false);
    },
    [
      activeFormType,
      filter,
      dateFilter,
      filterSearchDto,
      orderBy,
    ]
  );
  
  const reloadData = useCallback(() => {
    setFilter((item) => ({ ...item, pageIndex: 0 }));
    setActiveCard(null);
    //getContactsData();
  }, []);


    const onCardClick = useCallback(
      (item, selectedIndex) => (event) => {
        event.stopPropagation();
        event.preventDefault();
        setActiveCard(item);
        dispatch(ActiveItemActions.activeItemRequest(item));
        if (getSideMenuStatus()) setAdvanceSearchBtn(true);
        sideMenuComponentUpdate(
          <CardDetailsComponent
            activeData={allDevelopers?.result[selectedIndex]}
            cardDetailsActionClicked={detailedCardSideActionClicked}
            loginResponse={loginResponse}
            onActionClicked={detailedCardActionClicked}
            relodedata={reloadData}
            displyOpenFileButton={displayOpenFileButton}
            contactPreferenceFieldThanFour={contactPreferenceFieldThanFour}
            closeSideMenu={closeSideMenu}
          />
        );
        sideMenuIsOpenUpdate(true);
      },
      [
        detailedCardActionClicked,
        detailedCardSideActionClicked,
        allDevelopers.result,
        loginResponse,
        reloadData,
        advanceSearchBtn,
      ]
    );

    const cardCheckboxClicked = useCallback(
      (itemIndex, element, isContactWithTransaction) => {
        if (
          !isContactWithTransaction ||
          activeSelectedAction === ActionsButtonsEnum[29].id
        ) {
          setCheckedCards((items) => {
            const index = items.findIndex((item) => item.id === element.id);
            if (index !== -1) items.splice(index, 1);
            else items.push(element);
            return [...items];
          });
          setCheckedCardsIds((items) => {
            const index = items.findIndex((item) => item === element.id);
            if (index !== -1) items.splice(index, 1);
            else items.push(element.id);
            return [...items];
          });
        } else
          showError(
            t(`${translationPath}absolete-contacts-shouldnt-have-transactions`)
          );
      },
      [activeSelectedAction]
    );
    
    const contactPreferenceFieldThanFour = (
      contactPreferenceType,
      item,
      contact_type
    ) => {
      const whatsappPolicy = contactPreferenceType?.some(
        (c) => c.value === 'WhatsApp'
      );
  
      if (+contact_type === 1) {
        const hasWhatsApp =
          item?.whatsapp_mobile?.phone === '********' ||
          item?.mobile?.phone === '********';
        if (whatsappPolicy && hasWhatsApp) {
          return false;
        }
      } else if (+contact_type === 2) {
        const hasLandline = item?.landline_number?.phone === '********';
        if (whatsappPolicy && hasLandline) {
          return false;
        }
      }
  
      return true;
    };
  

    const onPageIndexChanged = (pageIndex) => {
      setFilter((item) => ({ ...item, pageIndex }));
    };
  
    const onPageSizeChanged = (pageSize) => {
      setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    };

    const getForm = useCallback(async () => {
      setIsLoading(true);
      const response = await formByIdGet({
        formname:'Developers'
        
      });
      if (!(response && response.status && response.status !== 200)) 
      setIsLoading(false);
    }, []);

    useEffect(() => {
      getForm();
     },[]);

    useEffect(() => {
    
        bottomBoxComponentUpdate(
          <PaginationComponent
            pageIndex={filter.pageIndex}
            pageSize={filter.pageSize}
            totalCount={allDevelopers.totalCount}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
          />
        );
    });
  
    useEffect(
      () => () => {
        bottomBoxComponentUpdate(null);
      },
      []
    );

    useEffect(() => {
      setDisplayOpenFileButton(
        havePermissionToViewDetails(DeveloperVerticalTabsData)
      );
    }, [isFirst]);

    return (
      <div className='view-wrapper developers-view'>
        <Spinner isActive={isLoading} isAbsolute />
        <div className='d-flex-column'>
          <div className='header-section'>
            <div className='filter-section'>
              <div className='section'>
                  <ButtonBase
                    className='btns theme-solid'
                    idRef='addnewDevelopersRef'
                    id='addnewDevelopers'
                    onClick={() => {
                      GlobalHistory.push(
                        '/home/developers/add'
                      );
                    }}
                  >
                    <span>
                      {t(`${translationPath}add-new-developer`)}
                    </span>
                  </ButtonBase>
                  <ButtonBase
                    className='btns theme-solid'
                    idRef='addnewDevelopersRef'
                    id='addnewDevelopers'
                    onClick={() => {
                      GlobalHistory.push(
                        `/home/developers/developer-profile-edit?id=${(activeItem && activeItem.id)||1}&formType=${(activeItem && activeItem.formType)|| 135 }`
                      );
                    }}
                  >
                    <span>
                      {t(`${translationPath}edit-developer`)}
                    </span>
                  </ButtonBase>
              </div>
                <div className='section autocomplete-section'>
                  <div className='d-flex-column px-2 w-100 p-relative mb-2'>
                    <div className='w-100 p-relative mb-2'>
                    </div>
                    <div className='agentSection pl-5-reversed'>
                      <div className='mr-1-reversed'>
                        
                      </div>
                      <div className='w-100 mr-1-reversed'>
                       
                      </div>
                      <div className='w-100 mr-1-reversed'>
                      </div>
                    </div>
                  </div>
  
                  <div className='view-search-wrapper'>
                    <ViewTypes
                      onTypeChanged={onTypeChanged}
                      initialActiveType={activeActionType}
                      activeTypes={[
                        ViewTypesEnum.tableView.key,
                        ViewTypesEnum.cards.key,
                      ]}
                      className='mb-3'
                    />
                  </div>
                </div>
            </div>
          <DevelopersCreatedOnFilter
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            orderFilter={orderFilter}
            selectedOrderBy={selectedOrderBy}
            setOrderBy={setOrderBy}
            setSelectedOrderBy={setSelectedOrderBy} 
            orderBy={orderBy}
            />
          </div>
          <>
              {(
                (activeActionType === ViewTypesEnum.tableView.key && (
                  <>
                     <DevelopersTable
                      data={allDevelopers }
                      translationPath={translationPath}
                      parentTranslationPath={parentTranslationPath}
                      setSortBy={setSortBy}
                      filter={filter}
                      isLoading={isLoading}
                      activeItem={activeItem}
                      setActiveItem={setActiveItem}
                     />
                  </>
                )) ||
                (activeActionType === ViewTypesEnum.cards.key && (
                  <>
                    <div className='view-wrapers'>
                      <DevelopersCards
                        data={allDevelopers && [{ id :111 , name : 'fsef' } ,  {id :112 , name : '2222222' }] }
                        translationPath={translationPath}
                        parentTranslationPath={parentTranslationPath}
                        activeCard={activeCard}
                        isExpanded={isExpanded}
                        onCardClicked={onCardClick}
                        onFooterActionsClicked={detailedCardSideActionClicked}
                        onActionClicked={detailedCardActionClicked}
                        withCheckbox={[
                          'merge',
                          ActionsButtonsEnum[4].id,
                          ActionsButtonsEnum[29].id,
                        ].includes(activeSelectedAction)}
                        onCardCheckboxClick={cardCheckboxClicked}
                        selectedCards={checkedCards}
                        isCheckBoxDisabled={
                          activeSelectedAction === 'merge'
                            ? checkedCards.length >= 10
                            : false
                        }
                        displyOpenFileButton={displayOpenFileButton}
                        contactPreferenceFieldThanFour={contactPreferenceFieldThanFour}
                        activeAction={activeSelectedAction}
        
                      />
                    </div>
                  </>
                ))
              )}
          </>
        </div>
      </div>
    );

    
  } ; 
  